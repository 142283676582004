// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import { Container, Grid, Link as MuiLink, ThemeProvider, Typography } from '@mui/material';
import { footerTheme } from '../Themes/Themes';
import { Language, t } from '../../i18n/i18n';
import Logo from '../Logo';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from '../../i18n/languages';

const Footer = () => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') ?? (DEFAULT_LANGUAGE as Language);
  return (
    <ThemeProvider theme={footerTheme}>
      <Container>
        <Grid container>
          {/* Left Content */}
          <Grid item>
            <Grid container>
              <Grid item display="flex">
                <Typography variant="body2">
                  {t(
                    {
                      defaultMessage: `Copyright © {year}. Immersive Technologies. All rights reserved.`,
                      id: '3HV21E',
                    },
                    { year: new Date().getFullYear() }
                  )}
                </Typography>
                <MuiLink
                  href="https://licensing.immersiveonline.com/"
                  target="_blank"
                  rel="noopener"
                  underline="always"
                  variant="body2">
                  {t({
                    defaultMessage: 'License',
                    id: 'HBxXD/',
                  })}
                </MuiLink>
              </Grid>
              <Grid item>
                <MuiLink href={`/faq?lang=${lang}`} target="_blank" rel="noopener" underline="always" variant="body2">
                  {t({
                    defaultMessage: 'FAQ',
                    id: 'W8nHSd',
                  })}
                </MuiLink>
              </Grid>
            </Grid>
          </Grid>
          {/* Right Content */}
          <Grid item>
            <Logo footer />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Footer;
