// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Dialog from '@mui/material/Dialog';
import { ErrorCode, getErrorCode, getErrorMessage } from '../../utils/errorUtils';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  CssBaseline,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import { t } from '../../i18n/i18n';
import {
  checkMFAStatus,
  confirmMFASetup,
  getLoginToken,
  getMFASetupCode,
  redirectBackToProduct,
} from '../../utils/authServiceUtils';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { setupMFATheme } from '../Themes/Themes';
import Logo from '../Logo';
import { LoadingButton } from '@mui/lab';

type MFAStatus = 'none' | 'loading' | 'submitting' | 'confirmed';

interface ISetupMFAState {
  errorCode: ErrorCode | null;
  isMFAMandatory: boolean;
  mfaEnabled: boolean;
  mfaStatus: MFAStatus;
  mfaUrl: string;
  secretCode: string;
  email: string;
  pin: string;
}

export const SetupMFA = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const redirectTo = searchParams.get('redirectTo');
  const csrfToken = searchParams.get('csrfToken');
  const navigate = useNavigate();
  const { redirectFromLogin } = location.state || {};

  const [setupMFAState, setSetupMFAState] = useState<ISetupMFAState>({
    errorCode: null,
    isMFAMandatory: false,
    mfaEnabled: false,
    mfaStatus: 'loading',
    mfaUrl: '',
    secretCode: '',
    email: '',
    pin: '',
  });

  useEffect(() => {
    const loadQRCode = async () => {
      try {
        const { isMFAEnabled, isMFAMandatory } = await checkMFAStatus();

        // If MFA is already enabled, redirect to the profile page
        if (isMFAEnabled) {
          return navigate({ pathname: '/profile', search: searchParams.toString() });
        }

        const { secretCode, email } = await getMFASetupCode();
        // Generate the QR code secret key URI
        // See: https://github.com/google/google-authenticator/wiki/Key-Uri-Format
        const mfaUrl = `otpauth://totp/ImmersiveCloud:${email}?secret=${secretCode}&issuer=ImmersiveCloud`;
        setSetupMFAState((prevState) => {
          return {
            ...prevState,
            errorCode: null,
            mfaStatus: 'none',
            mfaUrl,
            email,
            secretCode,
            isMFAMandatory,
          };
        });
      } catch (err) {
        // Return to the login page if the session has expired
        const errorCode = getErrorCode(err);
        if (errorCode === ErrorCode.SessionExpired) {
          return navigate({ pathname: '/login', search: searchParams.toString() });
        } else {
          // Log unhandled errors
          console.error(`Failed to load QR code:  ${(err as Error).message} `);
        }

        // If QR code fails to load, redirect to the error page
        return navigate({ pathname: `/error/${errorCode}`, search: searchParams.toString() });
      }
    };

    // if the redirectTo is not set then navigate to an error page
    if (!redirectTo) {
      return navigate({ pathname: `/error/${ErrorCode.Unknown}`, search: searchParams.toString() });
    } else {
      loadQRCode();
    }
  }, [navigate, redirectTo, searchParams, setSetupMFAState]);

  const onPinChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSetupMFAState({
      ...setupMFAState,
      pin: event.target.value,
      errorCode: null,
    });
  };

  const onSubmit = async () => {
    try {
      if (!setupMFAState.pin) {
        throw new Error(ErrorCode.MFACodeRequired);
      }
      setSetupMFAState({
        ...setupMFAState,
        errorCode: null,
        mfaStatus: 'submitting',
      });
      await confirmMFASetup(setupMFAState.pin);
      setSetupMFAState({
        ...setupMFAState,
        errorCode: null,
        mfaStatus: 'confirmed',
      });
    } catch (err) {
      // Return to the login page if the session has expired
      const errorCode = getErrorCode(err);
      if (errorCode === ErrorCode.SessionExpired) {
        return navigate({ pathname: '/login', search: searchParams.toString() });
      } else {
        // Log unhandled errors
        console.error(`Failed to set up 2-Factor Authentication:  ${(err as Error).message} `);
      }

      setSetupMFAState({
        ...setupMFAState,
        errorCode,
        mfaStatus: 'none',
      });
    }
  };

  // Handle redirecting the user based on the context
  // If MFA setup is initiated from the login page, redirect to the product page after successful setup
  // Otherwise, redirect to the Immersive Cloud Profile page
  const handleRedirect = useCallback(async () => {
    setSetupMFAState((prevState) => {
      return {
        ...prevState,
        mfaStatus: 'loading',
      };
    });
    if (redirectFromLogin && csrfToken) {
      try {
        const token = await getLoginToken(csrfToken);
        redirectBackToProduct(redirectTo!, { token });
        return;
      } catch (err) {
        const errorCode = getErrorCode(err);
        // Log unhandled errors
        console.error(`Failed to redirect back to product ${redirectTo}:  ${(err as Error).message} `);
        return navigate({ pathname: `/error/${errorCode})}`, search: searchParams.toString() });
      }
    } else {
      return navigate({ pathname: '/profile', search: searchParams.toString() });
    }
  }, [redirectFromLogin, navigate, redirectTo, searchParams, csrfToken]);

  useEffect(() => {
    if (setupMFAState.mfaStatus === 'confirmed') {
      handleRedirect();
    }
  }, [setupMFAState.mfaStatus, handleRedirect]);

  return (
    <ThemeProvider theme={setupMFATheme}>
      <CssBaseline />
      <Dialog open>
        <Logo />
        <DialogTitle id="form-dialog-title">
          {t({ defaultMessage: `Register 2-Step Authentication`, id: 'x+ZsOB' })}
        </DialogTitle>
        <DialogContent>
          {setupMFAState.mfaStatus === 'loading' ? (
            <CircularProgress color="info" size={24} sx={{ marginTop: 5 }} />
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t({
                    defaultMessage: `Scan the QR code into an Authenticator App such as Microsoft Authenticator or Google Authenticator.`,
                    id: 'yTq8Ps',
                  })}
                </Typography>
              </Grid>
              <Grid item sm={12} marginBlock={3}>
                <QRCodeCanvas value={setupMFAState.mfaUrl} />
              </Grid>
              <Grid item xs={12}>
                <Accordion variant="outlined">
                  <AccordionSummary>
                    {t({
                      defaultMessage: `Unable to scan?`,
                      id: 'b9eRlo',
                    })}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      {t({
                        defaultMessage: `Enter the following details manually.`,
                        id: 'ZuWaIT',
                      })}
                    </Typography>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        {t({
                          defaultMessage: `Account: `,
                          id: 'ALR7z7',
                        })}
                        <strong>{`ImmersiveCloud:${setupMFAState.email}`}</strong>
                      </Typography>
                      <Typography variant="body2">
                        {t({
                          defaultMessage: `Key: `,
                          id: 'JGRk0h',
                        })}
                        <strong>{setupMFAState.secretCode}</strong>
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="pin"
                  variant="outlined"
                  autoFocus
                  margin="dense"
                  type={'text'}
                  autoComplete="pin"
                  fullWidth
                  error={setupMFAState.errorCode !== null}
                  helperText={getErrorMessage(setupMFAState.errorCode)}
                  placeholder={t({
                    defaultMessage: 'Enter the pin from the Authenticator App',
                    id: 'vyh0Ye',
                  })}
                  value={setupMFAState.pin}
                  onChange={onPinChange}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  variant={'contained'}
                  color="primary"
                  loading={setupMFAState.mfaStatus === 'submitting'}
                  onClick={onSubmit}>
                  {t({
                    defaultMessage: 'Submit',
                    id: 'wSZR47',
                  })}
                </LoadingButton>
              </Grid>
              {!setupMFAState.isMFAMandatory && (
                <Grid item xs={12}>
                  {redirectFromLogin ? (
                    <MuiLink
                      target="_blank"
                      color="inherit"
                      underline="always"
                      variant="body2"
                      onClick={handleRedirect}>
                      {t({
                        defaultMessage: 'I will do it later',
                        id: 'PvrI2D',
                      })}
                    </MuiLink>
                  ) : (
                    <Button variant="contained" color="secondary" onClick={handleRedirect}>
                      {t({ defaultMessage: 'Back', id: 'cyR7Kh' })}
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};
