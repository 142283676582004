// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

// Check if the password matches the minimum length requirement
export const validatePasswordLength = (password: string): boolean => {
  if (password.length >= 8) {
    return true;
  }

  return false;
};
