// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Alert from '@mui/material/Alert';
import { t } from '../../../i18n/i18n';
import { EMAIL_SENDER } from '../../../Constants/Constants';

export const EmailSuccessPage = ({ email }: { email: string }) => (
  <Alert className="success-alert" severity="success">
    {t(
      {
        defaultMessage: 'A recovery link has been sent to {email} from {sender}. This may take a few minutes.',
        id: '5Lemk1',
      },
      {
        email: <a href={`mailto:${email}`}>{email}</a>,
        sender: <a href={`mailto:${EMAIL_SENDER}`}>{EMAIL_SENDER}</a>,
      }
    )}
    <br />
    <strong>
      {t({
        defaultMessage: 'Please also check your spam folder.',
        id: 'l+CadZ',
      })}
    </strong>
  </Alert>
);
