// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Dialog from '@mui/material/Dialog';
import LoadingPage from '../Common/LoadingPage';
import { ErrorCode, getErrorMessage } from '../../utils/errorUtils';
import { ChangeEvent, useState } from 'react';
import { ReturnToLoginLink } from '../Common/ReturnToLoginLink';
import { EmailSuccessPage } from './ForgotPwdPages/EmailSuccessPage';
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { emailFormatValidation } from '../../utils/emailUtils';
import { t } from '../../i18n/i18n';
import { sendResetEmail } from '../../utils/authServiceUtils';
import { useLocation, useSearchParams } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from '../../i18n/languages';
import { forgotPwdTheme } from '../Themes/Themes';
import Logo from '../Logo';

interface IForgotPwsState {
  email: string;
  errorCode: ErrorCode | null;
  isLoading: boolean;
  isSending: boolean;
  isSuccess: boolean;
}

export const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');
  const lang = searchParams.get('lang');
  const location = useLocation();
  const email = location.state;

  const [forgotPwdState, setForgotPwdState] = useState<IForgotPwsState>({
    email: email || '',
    errorCode: null,
    isLoading: false, // Indicates whether the page is loading
    isSending: false, // Indicates whether the email is currently being sent
    isSuccess: false, // Indicates whether the email was sent successfully
  });

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForgotPwdState({ ...forgotPwdState, email: event.target.value, errorCode: null });
  };

  const onEnter = async () => {
    try {
      // Set an error state if the redirect url can not be found
      if (!redirectTo) {
        setForgotPwdState({ ...forgotPwdState, errorCode: ErrorCode.Unknown });
      } else if (!emailFormatValidation.test(forgotPwdState.email)) {
        setForgotPwdState({ ...forgotPwdState, errorCode: ErrorCode.EmailInvalid });
        // only try and process the request we are not already processing one
      } else if (!forgotPwdState.isSending) {
        setForgotPwdState({ ...forgotPwdState, errorCode: null, isSending: true });
        await sendResetEmail(forgotPwdState.email, redirectTo, lang || DEFAULT_LANGUAGE);
        setForgotPwdState({ ...forgotPwdState, errorCode: null, isSending: false, isSuccess: true });
      }
    } catch (err) {
      // Log unhandled errors
      console.error(`Failed to send the password reset email: ${(err as Error).message}`);
      setForgotPwdState({ ...forgotPwdState, isSending: false, errorCode: ErrorCode.EmailSendFailed });
    }
  };

  return (
    <ThemeProvider theme={forgotPwdTheme}>
      <CssBaseline />
      <Dialog open>
        {forgotPwdState.isLoading ? (
          <LoadingPage />
        ) : (
          <Box>
            <Logo />
            <DialogTitle id="form-dialog-title">{t({ defaultMessage: `Can't log in?`, id: 'dvwUVb' })}</DialogTitle>
            <DialogContent>
              {!forgotPwdState.isSuccess ? (
                <Box>
                  <Typography variant="subtitle1">
                    {t({
                      defaultMessage: `A recovery link will be sent to:`,
                      id: 'A1GrFj',
                    })}
                  </Typography>
                  <TextField
                    label={t({
                      defaultMessage: 'Enter your email',
                      id: '5MDGuM',
                    })}
                    disabled={email}
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    type="text"
                    autoComplete="email"
                    fullWidth
                    error={forgotPwdState.errorCode !== null}
                    helperText={getErrorMessage(forgotPwdState.errorCode)}
                    value={forgotPwdState.email}
                    onChange={onEmailChange}
                    onKeyDown={(ev) => {
                      if (ev.key === 'Enter') {
                        onEnter();
                      }
                    }}
                  />
                  <Button
                    className="send-link"
                    fullWidth
                    disableElevation
                    variant={'contained'}
                    color="primary"
                    onClick={onEnter}>
                    {!forgotPwdState.isSending ? (
                      t({
                        defaultMessage: 'Send recovery link',
                        id: 'AeOnbI',
                      })
                    ) : (
                      <CircularProgress color="info" size={24} />
                    )}
                  </Button>
                </Box>
              ) : (
                <EmailSuccessPage email={forgotPwdState.email} />
              )}
              <ReturnToLoginLink />
            </DialogContent>
          </Box>
        )}
      </Dialog>
    </ThemeProvider>
  );
};
